*{
  background-color: pink;
  margin: 0;
  padding: 0;
}
.App{
  width: 100%;
  height: 100%;
  margin: 10px;
  padding: 1px;
  text-align: center;
}

span{
  font-size: 30px;
  color: red;
}

p{
  margin-top: 2vh;
  text-align: center;
  margin: 10px;
  padding: 10px;
}

footer{
  bottom: 0;
  margin: auto;
  padding: 15px;
  text-align: center;
}


img{
  width: 5vw;
  height: 10vh;
  display: inline-block;
  margin-top: 2vh;
}

@media (max-width: 550px) {
  .cora {
    width: 80vw; /* Adjust to a percentage for responsiveness */
    height: auto; /* Maintain aspect ratio */
  }

  img {
    width: 10vw;
    height: auto;
    margin-top: 2vh;
  }
}

@media (min-width: 550px){
.cora{
  width: 50%;
  height: 50%;
}
}

iframe{
  border: solid red;
  margin-top: 2vh;
  width: 75vw;
  height: 50vh;
}